
<script setup lang="ts">
import { ref, computed, Ref, defineProps, defineEmits } from "vue";
import { DataObject } from "o365-dataobject";
import type { NodeItem } from 'o365-nodedata';

// following import modifies prototype of dataObject by aggregatian a nodeData as prop to it.  
import 'o365-nodedata';

import OrgUnitFolder from "o365.vue.components.OrgUnitChooser.folderview.FolderViewItem.vue";
import FolderViewHeader from "o365.vue.components.OrgUnitChooser.folderview.FolderViewHeader.vue";

const props = defineProps<{
    treeObject: DataObject;
    dsOrgUnits: DataObject;
}>();

const emits = defineEmits<{
    (event: "contextChanged", node: NodeItem): void;
}>();

// The 'key' variable triggers the rerendering of the OrgUnits list,
// ensuring that the scroll bar resets to the top of the list.
let key = crypto.randomUUID();

// variable stores key value of last opened folder, empty string if none was opened 
const currentFolder: Ref<string> = ref("");
const currentRow: Ref<NodeItem> = ref(null);

const searchFilter: Ref<string> = ref("");

// isSearchResult var takes control over layout showing to user,
// it sets to true once search is done and to false when user
// navigates to ANY folder right after search.
// const isSearchResult: Ref<boolean> = ref(false);
const nodesHierarchy: Ref<NodeItem[]> = ref([]);

const contextChanged = (node: NodeItem) => emits("contextChanged", node);

const expandFolder = async (row: NodeItem, isCalledFromBreadCrumb?: boolean): Promise<void> => {
    currentRow.value = row;

    if(row.isLoading) {
        await row.loadingPromise;
    }
    
    currentFolder.value = row.key; 
    row.expand();
    key = crypto.randomUUID();

    // remove all elements which goes after choosen node on the breadcrumb 
    // or add a node to breadcrumb on node expand.
    if (isCalledFromBreadCrumb) {
        const foundedIndex = nodesHierarchy.value.findIndex((node)=> node.ID === row.ID );
        nodesHierarchy.value.splice(foundedIndex + 1);
    } else {
        nodesHierarchy.value.push(row);
    }
}

// expand parent folder. Doesn't work when search!
const goBack = (node: NodeItem): number | void => {
    // quit function  if it's top level
    if (!currentRow.value) {
        return;
    }

    //remove current folder from directory before go back
    nodesHierarchy.value.pop();
    key = crypto.randomUUID();

    // show roots folder on go back of 0 level folders.
    if (currentRow.value.level == 0) {
        currentFolder.value = "";
        return;
    }

    node = node.getParent();
    currentRow.value = node;
    return currentFolder.value = node.key; 
}

const choosenFolder = computed((): NodeItem | void => {
    if (!currentFolder.value) {
        return;
    }

    const choosenFolder = props.treeObject.data.filter((node: NodeItem) => node.key === currentFolder.value);
    
    // clear nodes array if user will be redirected to root foolder
    // since we are at the start
    if (!choosenFolder.length) {
        currentFolder.value = "";
        console.log("choosenFolder ", choosenFolder);
        nodesHierarchy.value.splice(0, nodesHierarchy.value.length);
    }

    return choosenFolder.length > 0 ? choosenFolder[0] : undefined;
});

</script>

<template>
    <FolderViewHeader
        :dataObject="treeObject" 
        :nodesHierarchy="nodesHierarchy"
        :currentFolder = "currentFolder"
        :currentRow = "currentRow"
        :choosenFolder="choosenFolder"
        :dsOrgUnits = "dsOrgUnits"

        v-model="searchFilter"
        @expandFolder = "expandFolder"
        @goBack = "goBack"
        @contextChanged = "contextChanged"
    />

    <template v-if = "!treeObject.state.isLoading">
        <!-- show root if neither of currentFolder and serchFilter are representet -->
        <div class = "overflow-auto" v-if="!choosenFolder && !searchFilter">
            
            <template v-for="node in treeObject.nodeData.root">
                <OrgUnitFolder :node = "node" @expandFolder="expandFolder" />
            </template>
        </div>

        <!-- show choosenFolder if currentFolder but no searchFilter --> 
        <div :key="key" v-else-if = "choosenFolder || choosenFolder && searchFilter" class="overflow-auto">   
            <template v-for="node in choosenFolder.details">
                <OrgUnitFolder :node = "node" @expandFolder="expandFolder" />
            </template>
        </div>

        <!-- If there's a search. && isSearchResult -->
        <div :key="key" class = "overflow-auto" v-else-if="searchFilter && !choosenFolder">
            <!-- <h2>search folder</h2>  for testing purpose-->

            <template v-for="node in treeObject.data">
                <OrgUnitFolder :node = "node" @expandFolder="expandFolder" />
            </template>
        </div>

    </template>

    <template v-else>
        <div class = " h-100 d-flex justify-content-center align-items-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </template>
</template>

<style>
.breadcrumb-item {
    display: flex;
    align-items: center;
    /* color: rgba(var(--bs-link-color-rgb)) */
}

.arrow-back {
    font-size: 1.5em;
}

.back-btn {
    background: transparent;
    border: none;
    width: 50px;
}

.button-container {
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden; /* Ensure overflow is hidden in parent */
    margin: 3px 0;
}
.choose-orgunit-btn {
    /* border-radius: 10% 0 0 10%; */
    border: 1px solid #EEEEEE;
    border-right: none;
    background-color: #D2E9E9;
}

.choose-folder-btn {
    border: 1px solid #D2E9E9;
    border-left: none;
    /* border-radius: 0 10% 10% 0; */
    background-color: #EEEEEE;;
}

.devided-button {
    width: 100%;
    height: 50px;
    cursor: pointer;
}

.shared-text {
    position: absolute;
    left: 0; /* Align text to the left */
    right: 0; /* Allow text to take up the full width */
    padding: 0 10px; /* Optional: add padding to give some space */
    font-size: 14px;
    pointer-events: none;
    user-select: none;
    white-space: nowrap;
    z-index: 1;
    color: black;
    overflow: hidden; /* Ensures overflow is hidden */
    text-overflow: ellipsis; 
}
</style>